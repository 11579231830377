import React from 'react'

const Hamburger = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M5 12H12H19" stroke="#100061" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 17H12H19" stroke="#100061" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 7H12H19" stroke="#100061" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default Hamburger
