import React, { useEffect, useRef } from 'react'
import styles from './ContentLinks.module.scss'
import Link from 'next/link'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import { NAVIGATION } from 'features/energy-gardens/landingpage/constants'
import { ContentLinksProps } from 'features/energy-gardens/landingpage/components/navigation/content-links/types'

const ContentLinks = ({ isSticky, dynamicContent, setShowMenu }: ContentLinksProps) => {
  // Local state
  const linkElements = useRef(new Array(0))

  // Router
  const router = useRouter()

  // Constants
  const navigation = NAVIGATION[router.query?.slug as string]?.[router.locale] ?? []
  const currentPage = navigation.find(({ page }) => {
    return page.href === `/${router.locale}${router.asPath.split('#')[0]}`
  })
  const links = dynamicContent ?? currentPage?.contentLinks

  // link observer
  useEffect(() => {
    const targets = document.querySelectorAll('[data-observerid]')
    const observerOptions = { rootMargin: '-30% 0% -70% 0%', threshold: 0 }
    const inViewHandler = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
          linkElements.current.forEach((linkEl: HTMLAnchorElement) => {
            linkEl?.setAttribute('data-activelink', linkEl?.href?.includes(`#${(entry.target as HTMLElement).id}`)?.toString())
          })
        }
      })
    }

    const observer = new IntersectionObserver(inViewHandler, observerOptions)
    targets.forEach((target) => observer.observe(target))

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <ul className={classNames(styles.list, isSticky && styles['is-sticky'])}>
      {links?.map(({ href, text }, idx) => (
        <li key={href}>
          <Link
            data-activelink={idx === 0}
            ref={(el: HTMLAnchorElement) => {
              linkElements.current.push(el)
            }}
            className={classNames(styles.link, { [styles['active-link']]: href.split('#')[1] === router.asPath.split('#')[1] })}
            href={href}
            scroll={false}
            onClick={() => setShowMenu && setShowMenu(false)}
          >
            {text}
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default ContentLinks
