import React from 'react'
import useWindowSize from 'hooks/useWindowSize'
import MobileNavigation from 'features/energy-gardens/landingpage/components/navigation/mobile-navigation/MobileNavigation'
import DesktopNavigation from 'features/energy-gardens/landingpage/components/navigation/desktop-navigation/DesktopNavigation'

const Navigation = () => {
  // Window size
  const { isDesktop } = useWindowSize()

  if (!isDesktop) {
    return <MobileNavigation />
  }

  return <DesktopNavigation />
}

export default Navigation
