import LoadingSkeleton from 'components/loading-skeleton/LoadingSkeleton'
import styles from './EnergieShareStatus.module.scss'
import useWindowSize from 'hooks/useWindowSize'
import classNames from 'classnames'
import { LoadingSkeletonColorVariant } from 'components/loading-skeleton/types'

const EnergyShareStatusLoader = () => {
  // Window size
  const { isTablet } = useWindowSize()

  return (
    <>
      <ul className={styles.list}>
        {[...new Array(4)].map((_, idx) => (
          <LoadingSkeleton
            variant={LoadingSkeletonColorVariant.INVERSE}
            key={idx}
            as="li"
            className={classNames(styles['list-item'], styles.skeleton)}
          >
            <LoadingSkeleton.Circle widthHeight={24} />
            <LoadingSkeleton.Rectangle height={20} />
            {!isTablet && (
              <>
                <LoadingSkeleton.Rectangle height={20} />
                <LoadingSkeleton.Rectangle height={20} width="66%" />
              </>
            )}
          </LoadingSkeleton>
        ))}
      </ul>

      <LoadingSkeleton variant={LoadingSkeletonColorVariant.INVERSE}>
        <LoadingSkeleton.Rectangle height={8} />
      </LoadingSkeleton>
    </>
  )
}

export default EnergyShareStatusLoader
