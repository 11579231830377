import React, { useEffect, useRef, useState } from 'react'
import ContentLinks from 'features/energy-gardens/landingpage/components/navigation/content-links/ContentLinks'
import { useRouter } from 'next/router'
import { MAIN_CONTAINER_ID, NAVIGATION } from 'features/energy-gardens/landingpage/constants'
import Link from 'next/link'
import styles from './MobileNavigation.module.scss'
import classNames from 'classnames'
import Icon from 'components/icon/Icon'
import Hamburger from 'components/icon/Hamburger'

const MobileNavigation = () => {
  // Router
  const router = useRouter()

  // Ref
  const rootEl = useRef<HTMLDivElement>(null)

  // Local state
  const [showMenu, setShowMenu] = useState<boolean>(false)

  // Constants
  const navigation = NAVIGATION[router.query?.slug as string]?.[router.locale] ?? []
  const currentPage = navigation.find(({ page }) => {
    return page.href === `/${router.locale}${router.asPath.split('#')[0]}`
  })

  useEffect(() => {
    // stop page scroll
    const bodeEl = document.querySelector('body')
    showMenu ? bodeEl.classList.add('no-scroll') : bodeEl.classList.remove('no-scroll')

    // reset scroll
    rootEl?.current?.scrollTo(0, 0)

    return () => {
      bodeEl.classList.remove('no-scroll')
    }
  }, [showMenu])

  return (
    <div ref={rootEl} className={classNames(styles.root, { [styles.open]: showMenu })}>
      <div className={classNames({ [styles.inner]: showMenu })}>
        <button className={classNames(styles.toggler)} onClick={() => setShowMenu(!showMenu)}>
          {showMenu ? <Icon name="close" /> : <Hamburger />}
          <span suppressHydrationWarning>{showMenu ? 'Sluit' : currentPage?.page?.text}</span>
        </button>
        {showMenu && (
          <nav className={styles.nav}>
            <ul>
              {navigation?.map(({ page }) => (
                <li key={page.href}>
                  <Link
                    href={page.href + `#${MAIN_CONTAINER_ID}`}
                    className={classNames(styles['page-link'], {
                      [styles['active-page-link']]: page.href === `/${router.locale}${router.asPath.split('#')[0]}`
                    })}
                  >
                    {page.text}
                  </Link>

                  {page.href === `/${router.locale}${router.asPath.split('#')[0]}` && !!currentPage?.contentLinks?.length && (
                    <div className={styles.contentLinks}>
                      <ContentLinks {...{ setShowMenu }} />
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        )}
      </div>
    </div>
  )
}

export default MobileNavigation
