import { EnergyGardens } from 'features/energy-gardens/types'
import { ENERGY_GARDEN_PHASES } from 'features/energy-gardens/constants'
import { EnergyGardenPhaseData } from 'features/energy-gardens/landingpage/types'

/**
 * Determines the Energy Garden phase data based on the amount of panels sold
 *
 * @param {EnergyGardens} energyGarden
 * @param {number} panelsSold
 * @returns {EnergyGardenPhaseData}
 */
export const determineEnergyGardenPhaseData = (energyGarden: EnergyGardens, panelsSold: number): EnergyGardenPhaseData => {
  const phases = ENERGY_GARDEN_PHASES[energyGarden]
  let summedPanelsForPhases = 0

  for (const phase in phases) {
    summedPanelsForPhases += phases[phase]

    if (panelsSold < summedPanelsForPhases) {
      // 1000 - (1744 - 872)
      const soldInPhase = panelsSold - (summedPanelsForPhases - phases[phase])

      return {
        availableInPhase: phases[phase] - soldInPhase,
        currentPhase: Number(phase),
        percentage: Math.trunc((soldInPhase / phases[phase]) * 100)
      }
    }
  }

  // Default return if more sold than available
  return {
    availableInPhase: 0,
    currentPhase: 4,
    percentage: 100
  }
}
