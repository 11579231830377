import React from 'react'
import { MAIN_CONTAINER_ID, NAVIGATION } from 'features/energy-gardens/landingpage/constants'
import { useRouter } from 'next/router'
import Link from 'next/link'
import classNames from 'classnames'
import styles from './DesktopNavigation.module.scss'
import Container from 'components/container/Container'
import OrderButton from 'features/energy-gardens/landingpage/components/order-button/OrderButton'

const DesktopNavigation = () => {
  // Router
  const { locale, asPath, query } = useRouter()
  const { slug } = query

  // Constants
  const navigation = NAVIGATION[slug as string]?.[locale] ?? []

  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.navigation}>
          <nav>
            <ul className={styles.list}>
              {navigation?.map(({ page }) => (
                <li key={page.href}>
                  <Link
                    href={page.href + `#${MAIN_CONTAINER_ID}`}
                    className={classNames(styles['page-link'], {
                      [styles['active-page-link']]: page.href === `/${locale}${asPath.split('#')[0]}`
                    })}
                    scroll={false}
                  >
                    {page.text}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>

          <OrderButton variant="primary" />
        </div>
      </Container>
    </div>
  )
}

export default DesktopNavigation
