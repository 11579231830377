import React, { PropsWithChildren } from 'react'
import { ArticleSectionProps } from 'features/energy-gardens/landingpage/components/article-section/types'
import styles from './ArticleSection.module.scss'
import classNames from 'classnames'

const ArticleSection = ({ id, isIntro, emphasize, children, className }: PropsWithChildren<ArticleSectionProps>) => {
  return (
    <section
      id={id}
      data-observerid={id}
      className={classNames(styles.root, className, { [styles.intro]: isIntro }, { [styles.emphasized]: emphasize })}
    >
      {children}
    </section>
  )
}

export default ArticleSection
