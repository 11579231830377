import React, { useMemo } from 'react'
import Container from 'components/container/Container'
import styles from './Sponsors.module.scss'
import { BruggeLogo, BruggeGeeftEnergieLogo, BruggeNaarMorgenLogo, TylesLogo, ExtraPowerLogo } from './logos'
import { useRouter } from 'next/router'

const Sponsors = () => {
  // Router
  const { locale } = useRouter()

  // Constants
  const logos = useMemo(
    () => [
      { component: <BruggeLogo />, href: 'https://www.brugge.be/' },
      { component: <BruggeGeeftEnergieLogo />, href: 'https://www.bruggegeeftenergie.be/' },
      { component: <BruggeNaarMorgenLogo />, href: 'https://www.brugge.be/klimaat' },
      { component: <TylesLogo />, href: `https://www.tyles.be/${locale}` },
      { component: <ExtraPowerLogo />, href: `https://www.extrapower.be/${locale}` }
    ],
    [locale]
  )

  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.layout}>
          <h2 className={styles.title}>In samenwerking met</h2>
          <ul className={styles.list}>
            {logos.map(({ component, href }) => (
              <li key={href} className={styles['list-item']}>
                <a href={href} className={styles.link} target="_blank" rel="noopener noreferrer">
                  {component}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </div>
  )
}

export default Sponsors
