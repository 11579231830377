import { Children, isValidElement, PropsWithChildren, useEffect, useRef } from 'react'
import styles from './Container.module.scss'
import type { AsideProps, ContainerProps, MainProps, PositionProps } from 'components/container/types'
import classNames from 'classnames'

const Aside = ({ children, sticky, top, position }: PropsWithChildren<AsideProps & PositionProps>) => {
  const elRef = useRef(null)
  useEffect(() => {
    if (sticky && elRef.current) elRef.current.style.setProperty('--js-sticky-top', `${top}px`)
  }, [sticky, top, elRef.current])
  return (
    <aside ref={elRef} className={classNames(styles[position], sticky && styles.sticky)}>
      {children}
    </aside>
  )
}

const LeftAside = (props: PropsWithChildren<AsideProps>) => {
  return <Aside position="left" {...props} />
}

const Main = ({ children, id, className }: PropsWithChildren<MainProps>) => {
  return (
    <main id={id} className={classNames(styles.main, className)}>
      {children}
    </main>
  )
}

const RightAside = (props: PropsWithChildren<AsideProps>) => {
  return <Aside position="right" {...props} />
}

const Container = ({ children, className, as = 'div' }: PropsWithChildren<ContainerProps>) => {
  // Semantic checks
  const hasMainContainer = Children.toArray(children).find(
    (child) => isValidElement(child) && (child as JSX.Element)?.type?.displayName === 'Main'
  )

  const HtmlTag = hasMainContainer && as === 'main' ? 'div' : as

  return <HtmlTag className={classNames(styles.container, className)}>{children}</HtmlTag>
}

LeftAside.displayName = 'LeftAside'
Main.displayName = 'Main'
RightAside.displayName = 'RightAside'

Container.LeftAside = LeftAside
Container.Main = Main
Container.RightAside = RightAside

export default Container
