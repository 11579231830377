import React, { PropsWithChildren } from 'react'
import Hero from 'components/sections/hero/Hero'
import Footer from 'components/footer/Footer'
import SunIcon from 'components/icon/SunIcon'
import Header from 'features/energy-gardens/common/header/Header'
import Navigation from 'features/energy-gardens/landingpage/components/navigation/Navigation'
import { useRouter } from 'next/router'
import { routes } from 'utils/routes'
import styles from './LandingPageLayout.module.scss'
import { LandingPageProps } from './types'
import Sponsors from 'features/energy-gardens/landingpage/components/sponsors/Sponsors'
import Seo from 'components/seo/Seo'
import EnergyShareStatus from 'features/energy-gardens/landingpage/components/energy-share-status/EnergyShareStatus'
import OrderButton from 'features/energy-gardens/landingpage/components/order-button/OrderButton'
import { BackgroundColors } from 'types/layout'

const LandingPageLayout = ({ children, energyGarden, pageTitle }: PropsWithChildren<LandingPageProps>): React.JSX.Element => {
  // Router
  const { locale } = useRouter()

  return (
    <div>
      <Seo
        title={`${energyGarden?.name || 'Energie tuin'} - ${pageTitle}`}
        description="De Brugse zonnetuin biedt Bruggelingen de kans om samen zonnepanelen te kopen op het dak van Brugs tegelbedrijf Tyles. Maak Brugse energie mee groen!"
        image="/images/seo/energy-gardens/og-brugse-zonnetuin.png"
      />

      {/* Header */}
      <Header badge={{ text: energyGarden?.name, icon: <SunIcon /> }} homePageLink={routes(locale).home} />

      {/* Hero */}
      <Hero
        image={{
          alt: '',
          customClass: styles['hero-image'],
          publicId: 'website/energy-gardens/brugse-zonnetuin'
        }}
        className={styles.hero}
        backgroundColor={BackgroundColors.YELLOW}
      >
        <Hero.Title value="Leg zonnepanelen in de Brugse zonnetuin" />
        <Hero.Paragraph value="De Brugse zonnetuin biedt jou de kans om te investeren in zonnepanelen op het dak van tegelbedrijf Tyles en 25 jaar lang te genieten van het rendement van de duurzame stroom die ze opwekken. De totale financiering van de Brugse zonnetuin gebeurt in meerdere fases, waarbij na elke opgehaalde fase de zonnepanelen van dat deel geïnstalleerd worden." />

        <EnergyShareStatus />

        <Hero.CtaGroup>
          <OrderButton variant="secondary-on-accent" />
        </Hero.CtaGroup>
      </Hero>

      {/* Navigation */}
      <Navigation />

      {/* Outlet */}
      <div className={styles.outlet}>{children}</div>

      {/* Sponsors */}
      <Sponsors />

      {/* Footer */}
      <Footer withColumns={false} />
    </div>
  )
}

export default LandingPageLayout
