import React from 'react'
import { routes } from 'utils/routes'
import { useGetEnergyGardenBySlugQuery } from 'store/api/boltApi'
import { useRouter } from 'next/router'
import mixpanel from 'mixpanel-browser'
import { ENERGY_GARDEN_TRACKING } from 'constants/tracking'
import { ButtonProps } from '@boltenergy-be/design-system/dist/components/button/types'
import Link from 'components/link/Link'

const OrderButton = ({ variant }: Pick<ButtonProps, 'variant'>) => {
  // Router
  const { locale, query } = useRouter()
  const { slug } = query

  // Redux query
  const { data, isLoading } = useGetEnergyGardenBySlugQuery(slug as string)
  const soldOut = data?.products[0].available <= 0

  return (
    <Link
      representation="button"
      variant={variant}
      loading={isLoading}
      disabled={soldOut}
      href={routes(locale, slug as string).energyGardenOrder}
      onClick={() => mixpanel.track(ENERGY_GARDEN_TRACKING.START_FLOW_CLICKED)}
    >
      {soldOut ? 'Uitverkocht' : 'Meedoen'}
    </Link>
  )
}

export default OrderButton
